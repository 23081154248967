/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	.content-header {
		@extend .container-fluid;
		padding: .3rem;
	}

	.logo-header {

		>img {
			@extend .img-responsive;
			display: inline-block;
			width: 12rem;
		}
	}
}
